@import '../theme';

.CharacteristicsSummary__Container {
	header {
		font-family: Raleway;
		font-size: 18px;
		text-align: left;
		font-weight: bold;
		color: $purple_wine;
	}
}
