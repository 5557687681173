.Dropover__Container {
	.Dropover__Status {
		margin-top: 50px;
		font-size: 18px;
		text-align: center;
	}

	/*.SelectionButton__Context {
		&.active {
			background-color: #d7d2db;
		}

		&:hover {
			background-color: #d7d2db;
		}
	}*/
}
