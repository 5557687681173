@import 'assets/scss/partials/_vars.scss';
@import 'assets/scss/partials/_mixins.scss';

.ListSelection__SubTitle {
	margin-top: 10px;
	margin-bottom: 20px;
	font-family: Raleway;
	font-size: 14px;
	color: #887794;
	text-align: center;
}

/* RadioList Selection */

.radio-list-selection {
	.selection-buttons {
		margin: 0;

		.selected-option.has-option {
			vertical-align: top;
			display: inline-block;
			padding: 2px 10px;
			color: $c_text_lightest;
			@include border_radius(3px);
			border: 1px solid $cp_primary;
			min-height: 20px;
			max-width: 100%;
			@include transition(background-color, 250ms, linear);
			@include transition(color, 250ms, linear);
			@include b-s(0, 2px, 0, 0, $c_box_shadow);
			background-color: $cp_primary;
			font-size: 12px;
			margin-bottom: 0;
		}
	}

	.selection-button {
		padding: 20px 9px 10px;
		text-align: center;
		border: 1px solid $c_border;
		border-right: 0;
		margin-bottom: 20px;
		color: $c_text_lighter;
		position: relative;
		height: 90px;
		/*display: table; */
		display: block;
		background: $cp_grey;

		&:hover {
			cursor: pointer;
		}

		&:first-child {
			-webkit-border-radius: 5px 0 0 5px;
			border-radius: 5px 0 0 5px;
		}

		&:last-child {
			border-right: 1px solid $c_border;
			-webkit-border-radius: 0 5px 5px 0;
			border-radius: 0 5px 5px 0;
		}

		&.is-active {
			color: #333;
			font-weight: bold;
		}

		&.hidden {
			display: none;
		}

		&.is-active:before {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-top-color: $c_border;
			border-width: 10px;
			margin-left: -10px;
		}

		&.is-active:after {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-top-color: $cp_grey_light;
			border-width: 8px;
			margin-left: -8px;
		}

		.selected-selection {
			/*  display: table-cell;
        vertical-align: middle;*/

			p {
				margin-bottom: 3px;
			}
		}
	}

	.list-options {
		li {
			padding: 0;
			background: $cp_grey_light;
		}

		label {
			width: 100%;
			height: 100%;
			padding: 1rem 1.25rem;
			margin: 0;
		}

		input {
			margin-right: 10px;
		}
	}
}
