@import 'assets/scss/partials/_mixins.scss';
@import '../_theme';

@keyframes translateModal {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.isFixed {
	bottom: 0;
}

.Modal__Wrapper {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100vh;
	width: 100%;
	z-index: 9999;
	background-color: $secondary;
	overflow-y: auto;
	overflow-x: hidden;
	animation-duration: 0.2s;
	animation-name: translateModal;

	.Modal__Container__Header {
		width: 100%;
		font-weight: normal;

		&,
		h1,
		h2 {
			text-align: center;
		}

		&.emptyHeader {
			padding-top: 0;
			min-height: 58px;
		}

		@include desktop {
			margin-bottom: 30px;
		}

		h1 {
			font-family: Abril Fatface;
			font-size: 28px;
			color: $purple_wine;
			text-align: center;

			@include desktop {
				font-size: 40px;
			}

			@include mobile {
				margin-bottom: 10px;
				font-size: 24px;
			}
		}

		h2 {
			font-family: Raleway;
			font-size: 14px;
			color: $purple_heather;
		}

		.Modal__Container__Header_SubTitle {
			max-width: 500px;
			margin: 0 auto;
			color: $primary;
		}

		.Modal__Container__Header_Title {
			@include no_select();

			font-family: Abril Fatface;
			font-size: 28px;
			color: $purple_wine;

			@include desktop {
				font-size: 40px;
			}

			@include mobile {
				font-size: 24px;
				margin-bottom: 20px;
			}
		}

		.Modal__Controls__Wrapper {
			.Modal__Controls__Container {
				display: flex;
				justify-content: space-between;
				max-width: 1200px;
				width: 100%;
				margin: 0 auto;
				padding: 15px;

				.Modal__Container__CloseButton {
					@include no_select();
					position: fixed;
					top: 0px;
					right: 0px;
					font-size: 9px;
					font-weight: normal;
					color: $purple_wine;
					cursor: pointer;
					z-index: 11;
					padding: 20px;
				}
			}
		}
	}

	.Modal__Container__Body {
		width: 100%;
		flex-grow: 1;
		display: flex;
		margin-bottom: 100px;

		.Modal__Container__BodyContent {
			flex: 1;
			padding: 10px 1vw;
			padding-top: 0;
			width: 100%;
		}
	}

	.Modal__Container__FooterWrapper {
		width: 100%;
		z-index: 10;
		background-color: $secondary;
		@include no_select();

		@include desktop {
			background-color: $secondary-dark;
		}

		@include mobile {
			background-color: $secondary-dark;
			.Button__Outlined {
				background-color: $secondary;
			}
		}

		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;

		.Modal__Container__FooterLeft {
			position: fixed;
			bottom: 0;
			left: 0;
		}

		.Modal__Container__FooterRight {
			position: fixed;
			bottom: 0;
			right: 0;
		}

		.Modal__Container__FooterContainer {
			margin: 0 auto;
			text-align: center;
		}

		.Modal__Container__Footer_Background {
			background: #f5f4ef;
		}
	}

	&.hasMoreContent {
		.Modal__Container__Header {
			z-index: 10;
		}

		.Modal__Container__FooterWrapper {
			position: fixed;
			bottom: 0;
			right: 0;
			left: 0;
			@include no_select();

			@include desktop {
				-webkit-appearance: none;
				box-shadow: 0 -40px 30px rgba(245, 244, 239, 1);
			}
		}

		.Modal__Container__MoreContent {
			position: fixed;
			bottom: -50px;
			height: 50px;
			width: 100%;
			-webkit-appearance: none;
			box-shadow: 0 -40px 30px rgba(245, 244, 239, 1);

			@include desktop {
				display: none;
			}
		}
	}
}
