@import 'assets/scss/partials/_mixins.scss';
@import 'components/shared/ui/_theme.scss';

.contest.ContestContest__Medal,
.contest.Contest__AssessmentFinal {
	.flex {
		justify-content: space-evenly;
	}
}

/* all below her ecan probably be deleted*/

.Contest__Medal_Item {
	width: 33.33%;
	float: left;
	margin-bottom: 20px;
	padding: 20px;

	@include mobile {
		width: 50%;
	}
}

.Contest__Medal__Wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.Contest__Medal__Circle {
	width: 80px;
	height: 80px;
	color: $primary;
	border-radius: 100%;
	background-color: $purple_heather;
	display: flex;
	justify-content: center;
	align-items: center;

	@include mobile {
		width: 60px;
		height: 60px;
	}

	.Contest__Medal__Circle_Text {
		font-size: 24px;
		font-weight: 900;

		@include mobile {
			font-size: 16px;
		}
	}
}

.Contest__Medal__Count {
	flex: 1;
	text-align: right;
	font-family: Raleway;
	font-size: 20px;
	font-weight: 600;

	@include mobile {
		font-size: 18px;
	}
}

.Contest__Medal_SubHeader {
	align-items: center;
	font-family: Raleway;
	font-size: 14px;
	padding: 3px;
	color: $primary;
	font-weight: 600;

	@include mobile {
		font-size: 12px;
	}
}

.contest {
	.button-nav {
		position: sticky;
		top: 49px;
		background-color: $secondary;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 30px 0 40px 0;
	}
}
