.container {
	display: flex;
}

.label {
	flex: 2;
	font-size: 14px;
	font-weight: 600;
}

.description {
	margin-top: 10px;
	font-size: 12px;
	font-weight: normal;
	color: rgba(0, 0, 0, 0.4);
}

.control {
	flex: 3;
}
