@import '../_vars';
@import '../mixins';
@import '../../../../components/shared/ui/_theme';

.rating-step {
	padding-bottom: 85px;
	position: relative;

	.Rating__Vertical__Column1 {
		position: absolute;
		left: 30%;
		border-left: 1px solid #391d4d17;
		height: 520px;
	}
	.Rating__Vertical__Column2 {
		position: absolute;
		left: 70%;
		border-left: 1px solid #391d4d17;
		height: 520px;
	}

	.Rating__Vertical__Text {
		font-weight: 400;
		color: $purple_wine;
		font-size: 20px;
		text-align: center;
		margin-bottom: 20px;
		font-family: 'Abril Fatface';
	}

	.sub-header {
		text-align: center;
		font-size: 22px;
		font-weight: normal;
		color: $c_text;
		margin-bottom: 0.7rem;
	}

	.rating-total-wrapper {
		// padding-bottom: 200px;
		margin-bottom: 15px;
		text-align: center;
		height: 95px;
		position: fixed;
		left: 0;
		width: 100%;
		background: $secondary;
		z-index: 4;
		top: 80px;
	}
}

.rating-board {
	@include desktop {
		margin-left: auto;
		margin-right: auto;
	}
}

.rating-list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		padding: 5px 15px;

		@include mobile {
			padding: 7px 15px;
		}
	}
}

.rating-name {
	width: 20%;
	float: left;

	h4 {
		font-weight: normal;
		color: $c_text_dark;
		margin: 0;
		font-size: 1.3rem;
	}
}

.rating-scale {
	width: 80%;
	float: left;

	.scale-min,
	.scale-max {
		width: 10%;
		display: inline-block;
		text-align: center;
		color: #777;
		font-weight: normal;
	}

	.scale {
		vertical-align: middle;
		width: 80%;
		margin-top: -10px;

		> span {
			width: 5%;
			height: auto;

			&:nth-child(2) {
				display: none !important;
			}

			&.selected {
				.dot {
					border: 6px solid $cp_primary;
					height: 20px;
					width: 20px;
					position: relative;
					bottom: -6px;
					background-color: transparent;
				}
			}
		}

		.dot-wrapper {
			display: block;
			margin: 10px 0;

			> span {
				&:last-child {
					top: 10px !important;
				}
			}
		}

		.dot {
			display: block;
			@include border_radius(50%);
			width: 6px;
			height: 6px;
			border: 5px double $cp_primary;
			margin: 0 auto;
			@include border_radius(20px);
		}

		.dot-empty {
			border: 1px solid $cp_primary;
		}

		.dot-full {
			border: 2px solid $cp_primary;
		}
	}
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
