@import 'components/shared/ui/_theme.scss';

.WineDetails__FeatureList {
	margin: 15px 0;
	color: $purple_wine;

	.WineDetails__FeatureItem {
		margin-bottom: 4px;
		display: flex;
		align-items: center;

		label {
			flex: 1;

			span {
				font-weight: 600;
			}
		}

		span {
			flex: 2;
			font-size: 14px;
			margin-bottom: 0.5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
