@import 'assets/scss/partials/_mixins.scss';
@import '../_theme';

.Price__Wrapper {
	display: flex;
	align-items: center;
}

.Price__Input {
	flex: 3;
	margin-bottom: -9px;

	@include mobile {
		margin-bottom: -19px;
	}

	&.disabled {
		border-bottom: none;
	}
}

.Currency__Input {
	flex: 1;
	padding-left: 10px;
}
