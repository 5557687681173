@import 'assets/scss/partials/_mixins.scss';
@import '../_theme';

.DialogBox__Backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	opacity: 0.7;
	height: 100vh;
	background-color: $primary;
	transition: opacity 0.15s linear;
}

.DialogBox__Wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
}

.DialogBox__Modal {
	width: 100%;
	position: relative;
	top: 50px;
	left: 50%;
	transform: translateX(-50%) !important;
	z-index: 9999;
	transform: translateX(0px);
	background-color: $secondary;
	font-family: $body_font_family;
	box-shadow: 0px 0px 5px #aaa;

	&.wide {
		@include desktop {
			width: 850px;
		}
	}

	.DialogBox__Container {
		padding: 30px;
	}

	.DialogBox__Container__Header {
		width: 100%;
		align-items: center;
		font-size: 24px;
		text-align: left;
		display: flex;
		color: $purple_wine;

		.DialogBox__Title {
			flex: 1;
			font-weight: bold;
		}

		.DialogBox__Container__CloseButton {
			font-size: 9px;
			font-weight: normal;
			color: $purple_wine;
			cursor: pointer;

			&.disabled {
				pointer-events: none;
			}
		}
	}

	.DialogBox__Container__Body {
		flex-grow: 1;
		display: flex;
		color: $purple_wine;
		font-size: 18px;
		text-align: left;
		line-height: 24px;

		.DialogBox__Container__BodyContent {
			flex: 1;
			margin: 20px 0px 20px 0px;
		}
	}

	.DialogBox__Container__FooterWrapper {
		width: 100%;
		background-color: $secondary-dark;

		.DialogBox__Container__FooterContainer {
			display: flex;
			align-items: center;
			padding: 0px 10px;

			&.reverseButtons {
				flex-direction: row-reverse;
			}

			button {
				margin: 10px auto;
				min-width: 140px;
				font-size: 18px;
			}
		}
	}
}
