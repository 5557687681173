@import '../_theme';
@import 'assets/scss/partials/_mixins.scss';

.spinner {
	animation: rotate 2s linear infinite;
	z-index: 2;
	position: absolute;
	top: 33%;
	left: 50%;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;

	& .path {
		text-shadow: 3px 3px red;
		stroke: $primary;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

@keyframes zoom {
	0% {
		scale: 1;
	}
	50% {
		scale: 1;
	}
	100% {
		scale: 1;
	}
}

.Spinner__Wrapper {
	animation: zoom 4s ease-in-out never;

	bottom: 20%;
	left: 50%;
	margin-left: -50px;
	width: 100px;
	z-index: 100;
	position: fixed;
	//background-color: rgba($secondary, 0.4);
	@include transition(display, 0.7s, ease);
	scale: 1.3;

	svg {
		filter: drop-shadow(0px 0px 10px #dad1e1);
	}

	&.initial,
	&.initial .spinner {
		position: initial;
		top: initial;
		left: initial;
		right: initial;
		bottom: initial;
		margin: 10px;
	}

	&.light {
		.spinner .path {
			stroke: $pink_greyed;
		}
	}

	&.small,
	&.inline {
		z-index: inherit;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0;
		scale: initial;
		width: initial;
		animation: zoom 4.5s none;
	}

	&.inline,
	&.small {
		position: relative;
		top: initial;
		left: initial;
		right: initial;
		bottom: initial;
	}

	&.small {
		.spinner {
			position: initial;
			margin: initial;
			width: 16px;
			height: 16px;
		}
	}

	&.transparent {
		background: initial;
	}
}
