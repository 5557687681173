@import 'assets/scss/partials/_mixins.scss';
@import '../_theme';

.TextArea__Wrapper {
	text-align: center;
	display: block;
	outline: none;

	textarea {
		width: 100%;
		padding: 10px;
		max-height: 150px;
		border: 1px solid $default_input_border;

		&:focus {
			box-shadow: 0px 0px 2px 2px $primary;
		}
	}

	:focus {
		outline: 1px;
	}

	::-webkit-input-placeholder {
		color: $primary-light;
	}
	:-moz-placeholder {
		color: $primary-light;
	}
	::-moz-placeholder {
		color: $primary-light;
	}
	:-ms-input-placeholder {
		color: $primary-light;
	}
}
