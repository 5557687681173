@import 'assets/scss/partials/_mixins.scss';
@import 'components/shared/ui/_theme.scss';

.PageHeader__Container {
	position: absolute;
	top: 45px;
	left: 0;
	right: 0;
	@include no_select();

	.PageHeader__Background {
		overflow-x: hidden;
		background-size: cover;
		background-image: url('./wine.png');
		background-color: $primary;
		padding: 20px;

		@include desktop {
			padding: 50px 20px 20px;
		}
	}

	.PageHeader__Content {
		width: 90%;
		margin: 0 auto;

		.PageHeader__Title {
			color: $white;
			font-size: 48px;
			font-family: 'Abril Fatface';
		}

		.PageHeader__Description {
			color: $white;
			font-size: 12px;
		}

		.PageHeader__Addon {
			float: left;
			width: 100%;
			margin-left: -10px;

			@include desktop {
				width: 40%;
			}
		}
	}
}

.isEmbedded {
	.PageHeader__Container {
		top: 0;
	}
	.PageHeader__Content {
		top: 150px;
	}
}
