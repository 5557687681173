@import 'assets/scss/partials/_vars.scss';
@import 'assets/scss/partials/_mixins.scss';
@import 'components/shared/ui/_theme.scss';

.EventList__Wrapper {
	@include no_select();

	.SearchBar__Default {
		margin: 20px 0px;
	}

	.EventList__Empty {
		margin: 20px 0px;

		a {
			font-weight: bold;
			text-transform: lowercase;
			color: $primary;
		}
	}

	@keyframes slideIn {
		0% {
			top: -50px;
			opacity: 0;
		}
		100% {
			top: 0px;
			opacity: 1;
		}
	}

	.EventList__Content {
		display: flex;
		top: 200px;
		position: relative;
		flex-direction: column;

		@include desktop {
			flex-direction: row;
		}

		.EventList__Items {
			flex: 3;
			color: $primary;

			@include desktop {
				margin-right: 30px;
			}
		}

		.EventList__Features {
			flex: 2;
			padding: 20px;
			color: $primary;
			margin: 0px -20px 20px -20px;
			background: $primary-grey;
			position: relative;
			animation-name: slideIn;
			animation-duration: 0.5s;

			@include desktop {
				padding: 40px;
				margin-left: 30px;
				margin-top: -150px;
				margin-top: -284px;
			}

			.EventList__Features__Title {
				font-size: 22px;
				font-family: Abril Fatface;
				padding: 10px 0px;
			}

			.EventList__Features__Description {
				font-family: Raleway;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				color: $primary;

				@include desktop {
					margin: 10px 0px 50px 0px;
				}
			}

			.EventList__Place {
				padding: 3px 0px;
			}

			.EventList__Date {
				padding: 3px 0px;
			}
		}
	}
}

.ContestItem__Wrapper {
	height: 150px;

	.EventList__Context__Title {
		font-size: 30px !important;
	}
}

// For event item
.EventItem__Wrapper {
	display: flex;
	border-bottom: 1px solid #b0a5b8;
	margin: 0px -12px 1px -12px;

	&:nth-last-child(1) {
		border-bottom: none;
	}

	@include desktop {
		&:nth-last-child(1) {
			border-bottom: 1px solid #b0a5b8;
		}
	}

	.EventItem__Image {
		padding: 4px;
		display: flex !important;
		justify-content: center;
		align-items: center;
		flex: 1;
		background-color: $primary;
	}

	.EventItem__Date {
		padding: 40px 0px;
		flex: 1;
		background-color: $primary;
		display: flex;
		text-align: center;
		flex-direction: column;
		font-weight: 900;
		display: none;

		@include desktop {
			display: block;
		}

		.EventItem__Day {
			flex: 1;
			font-size: 24px;
			font-family: 'Raleway';
			font-weight: bold;
			color: $purple_heather;
		}

		.EventItem__Month {
			flex: 1;
			font-size: 12px;
			font-weight: bold;
			font-family: 'Raleway';
			color: $purple_greyed_light;
		}
	}

	.EventItem__Details {
		flex: 8;

		.EventItem__Context__Wrapper {
			padding: 3px;
			display: flex;
			height: 100%;
			align-items: center;
			cursor: pointer;
			background-color: #f5f4ef;

			@include desktop {
				padding: 5px;

				&:hover {
					background-color: #d7d2db;
				}
			}

			.EventList__Context {
				flex: 1;
				display: flex;
				padding: 15px;
				flex-direction: column;
				justify-content: center;

				.EventList__Context__Title {
					font-weight: bold;
					font-size: 18px;
					font-family: 'Raleway';
				}

				.EventList__Context__Group {
					padding: 3px 0px;
					font-size: 15px;

					span {
						font-weight: 600;
					}
				}

				.EventList__Context__Time {
					font-size: 14px;
					padding: 4px 0px;

					display: flex;
					flex-direction: column;

					@include desktop {
						flex-direction: row;
					}

					.EventList__Date {
						flex: 1;
					}

					.EventList__Place {
						flex: 1;
					}
				}
			}

			.EventList__Context__Chevron {
				float: right;
				padding: 10px;

				@include desktop {
					padding: 15px;
				}

				@include mobile {
					padding: 7.5px 10px;
				}
			}
		}
	}
}

.EventFeature__Wrapper {
	.EventItem__Wrapper {
		margin: 34px 0;

		@include mobile {
			margin: 20px auto;
			width: 80%;
			min-width: 350px;
		}

		.EventItem__Date {
			flex: 1;
		}

		.EventItem__Details {
			flex: 5;
		}
	}
}
