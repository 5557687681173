@import '../_theme';

$unchecked: url('../RadioSelection/Icon_RadioDefault.svg');
$checked: url('../RadioSelection/Icon_RadioSelected.svg');

.Radio__Container {
	position: relative;
	display: flex;
	cursor: pointer;
	flex-direction: row;
	width: 100%;
	font-family: $body_font_family;
	color: $purple_wine;

	&.disabled {
		opacity: 0.7;

		&,
		input,
		label.Radio__Label {
			cursor: not-allowed;
		}
	}

	input {
		margin-right: 20px;
		cursor: pointer;
		visibility: hidden;
	}

	input + .Radio__Wrapper::before {
		content: '';
		position: absolute;
		background: $unchecked center center;
		width: 18px;
		height: 18px;
		left: 0;
		top: 3px;
		visibility: visible;
		color: $purple_wine;
	}

	input:checked + .Radio__Wrapper::before {
		background: $checked center center;
	}

	.Radio__Wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.Radio__SubLabel {
		font-size: 15px;
	}

	label.Radio__Label {
		flex: 1;
		display: block;
		font-size: 18px;
		font-weight: 600;
		text-align: left;
		cursor: pointer;
	}

	&.small {
		label.Radio__Label {
			font-size: 14px;
			font-weight: normal;
			margin-bottom: 25px;
		}
	}
}
